@use "../../../../themes/BHE-Public-SiteTheme/assets/scss/utilities/utilities"as *;

.scs-image .scs-image-caption {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.54);
    padding: 0.5em;
    color: #fff;
}

.scs-image a:active {
    font-weight: 400;
}

.scs-image-style-2 .scs-component-content {
    border: 1px solid #999;
    box-sizing: border-box;
    padding: 5px;
}

.scs-image-style-2 .scs-image-caption {
    left: 6px;
    bottom: 6px;
    right: 6px;
}

.scs-image-style-8 .scs-component-content {
    border: 2px solid #333;
    box-sizing: border-box;
    padding: 5px;
}

.scs-image-style-8 .scs-image-caption {
    left: 5px;
    bottom: 5px;
    right: 5px;
}

.scs-image-style-9 .scs-component-content {
    border: 5px solid #333;
    box-sizing: border-box;
    padding: 5px;
}

.scs-image-style-9 .scs-image-caption {
    left: 5px;
    bottom: 5px;
    right: 5px;
}

.scs-image-style-10 .scs-component-content {
    box-shadow: -5px 5px 5px 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
}

.scs-image-style-11 .scs-component-content {
    box-shadow: 5px 5px 5px 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
}

.scs-image-style-12 .scs-component-content {
    box-shadow: -5px 5px 0 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
}

.scs-image-style-13 .scs-component-content {
    box-shadow: 5px 5px 0 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
}

.scs-image-style-14 .scs-component-content {
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.8);
    box-sizing: border-box;
}

.scs-image-hero-top{
    img {
        object-position: top;
    }
}

.scs-image-hero-center{
    img {
        object-position: center;
    }
    
}

.scs-image-hero-bottom{
    img {
        object-position: bottom;
    }
    
}

.scs-gallery-style-15 .scs-component-content,
.scs-image-style-15 .scs-component-content {
    position: relative;
}

.scs-gallery-style-15 .scs-component-content:after,
.scs-gallery-style-15 .scs-component-content:before,
.scs-image-style-15 .scs-component-content:after,
.scs-image-style-15 .scs-component-content:before {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width: 300px;
    background: #777;
    box-shadow: 0 15px 10px #777;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
}

.scs-gallery-style-15 .scs-component-content:after,
.scs-image-style-15 .scs-component-content:after {
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    right: 10px;
    left: auto;
}

.scs-image-style-15 .scs-image-image {
    box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.2) inset;
    border: 1px rgba(0, 0, 0, 0.1) solid;
}

.scs-gallery-style-16 .scs-component-content,
.scs-image-style-16 .scs-image-image {
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
    border: 10px #fff solid;
    box-sizing: border-box;
    border-radius: 3px;
}

.scs-image-style-16 .scs-image-caption {
    left: 10px;
    bottom: 10px;
    right: 10px;
}

.scs-app-style-2 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-2>.scs-component-content,
.scs-document-style-2 .scs-component-content,
.scs-gallery-style-2 .scs-component-content,
.scs-map-style-2 .scs-component-content,
.scs-socialbar-style-2 .scs-component-content,
.scs-video-style-2 .scs-component-content,
.scs-youtube-style-2 .scs-component-content iframe {
    border: 1px solid #999;
    box-sizing: border-box;
    padding: 5px;
}

.scs-app-style-8 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-8>.scs-component-content,
.scs-document-style-8 .scs-component-content,
.scs-gallery-style-8 .scs-component-content,
.scs-map-style-8 .scs-component-content,
.scs-socialbar-style-8 .scs-component-content,
.scs-video-style-8 .scs-component-content,
.scs-youtube-style-8 .scs-component-content iframe {
    border: 2px solid #333;
    box-sizing: border-box;
    padding: 5px;
}

.scs-app-style-9 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-9>.scs-component-content,
.scs-document-style-9 .scs-component-content,
.scs-gallery-style-9 .scs-component-content,
.scs-map-style-9 .scs-component-content,
.scs-socialbar-style-9 .scs-component-content,
.scs-video-style-9 .scs-component-content,
.scs-youtube-style-9 .scs-component-content iframe {
    border: 5px solid #333;
    box-sizing: border-box;
    padding: 5px;
}

.scs-app-style-10 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-10>.scs-component-content,
.scs-document-style-10 .scs-component-content,
.scs-gallery-style-10 .scs-component-content,
.scs-map-style-10 .scs-component-content,
.scs-socialbar-style-10 .scs-component-content,
.scs-video-style-10 .scs-component-content,
.scs-youtube-style-10 .scs-component-content iframe {
    box-shadow: -5px 5px 5px 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
}

.scs-app-style-11 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-11>.scs-component-content,
.scs-document-style-11 .scs-component-content,
.scs-gallery-style-11 .scs-component-content,
.scs-map-style-11 .scs-component-content,
.scs-socialbar-style-11 .scs-component-content,
.scs-video-style-11 .scs-component-content,
.scs-youtube-style-11 .scs-component-content iframe {
    box-shadow: 5px 5px 5px 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
}

.scs-app-style-12 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-12>.scs-component-content,
.scs-document-style-12 .scs-component-content,
.scs-gallery-style-12 .scs-component-content,
.scs-map-style-12 .scs-component-content,
.scs-socialbar-style-12 .scs-component-content,
.scs-video-style-12 .scs-component-content,
.scs-youtube-style-12 .scs-component-content iframe {
    box-shadow: -5px 5px 0 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
}

.scs-app-style-13 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-13>.scs-component-content,
.scs-document-style-13 .scs-component-content,
.scs-gallery-style-13 .scs-component-content,
.scs-map-style-13 .scs-component-content,
.scs-socialbar-style-13 .scs-component-content,
.scs-video-style-13 .scs-component-content,
.scs-youtube-style-13 .scs-component-content iframe {
    box-shadow: 5px 5px 0 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
}

.scs-app-style-14 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-14>.scs-component-content,
.scs-document-style-14 .scs-component-content,
.scs-gallery-style-14 .scs-component-content,
.scs-map-style-14 .scs-component-content,
.scs-socialbar-style-14 .scs-component-content,
.scs-youtube-style-14 .scs-component-content iframe {
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.8);
    box-sizing: border-box;
}

.core-principle-small {
    //height: 9rem;
}

.hero-bar-top{
    .background{
        img{
            object-position: top;
        }
    }
}

.hero-bar-center{
    .background{
        img{
            object-position: center;
        }
    }
}

.hero-bar-bottom{
    .background{
        img{
            object-position: bottom;
        }
    }
}

.scs-sectionlayout {
    &-space-between {
        justify-content: space-between;

        .sl-two-columns,
        .sl-three-columns {
            justify-content: space-between;

            @include mq(tablet) {
                gap: 30px;
            }
        }
    }

    &-space-around {
        justify-content: space-around;

        .sl-two-columns,
        .sl-three-columns {
            justify-content: space-around;

            @include mq(tablet) {
                gap: 30px;
            }
        }
    }

    &-bleed {

        .sl-two-columns,
        .sl-three-columns {
            max-width: var(--page-width);
            margin: auto;

            @include mq(tablet) {
                gap: 30px;
            }
        }
    }

    &-no-wrap .sl-horizontal-row {
        flex-wrap: unset;
    }

    .sl-horizontal-row {
        flex-wrap: wrap;
    }

    &-centered {

        .sl-two-columns,
        .sl-three-columns {
            justify-content: center;
        }
    }

    &-horizontal-no-flex-grow {
        .sl-horizontal-item {
            flex-grow: 0;
        }

        .sl-horizontal-row {
            gap: 1rem;
        }
    }

    &-buttons{
        .sl-horizontal-row {  justify-content: center;}
        .sl-horizontal-item {  flex: 0;}
    }
}

.scs-componentgroup-buttons{
    .sl-horizontal-row {  justify-content: center;}
    .sl-horizontal-item {  flex: 0;}
}

.scs-componentgroup-grid {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.scs-componentgroup-gray-bleed {
    background-color: #444444;
    width: 100vw;
    margin-left: calc(50% - 50vw);
}

.scs-componentgroup-page-width {
    max-width: var(--page-width);
    margin-inline: auto;

    @media screen and (max-width: 1439px){
        margin-inline: auto;
        padding-inline: 4rem;
    }

    @media screen and (max-width: 767px){
        margin-inline: 0;
        padding: 0;
    }
}

.scs-componentgroup-style-2 {
    border: 1px solid #999;
    box-sizing: border-box;
    padding: 5px;
    margin: 5px;
}

.scs-componentgroup-style-8 {
    border: 2px solid #333;
    box-sizing: border-box;
    padding: 5px;
    margin: 5px;
}

.scs-componentgroup-style-9 {
    border: 5px solid #333;
    box-sizing: border-box;
    padding: 5px;
    margin: 5px;
}

.scs-componentgroup-style-10 {
    box-shadow: -5px 5px 5px 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
    margin: 5px;
}

.scs-componentgroup-style-11 {
    box-shadow: 5px 5px 5px 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
    margin: 5px;
}

.scs-componentgroup-style-12 {
    box-shadow: -5px 5px 0 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
    margin: 5px;
}

.scs-componentgroup-style-13 {
    box-shadow: 5px 5px 0 0 rgba(51, 51, 51, 0.3);
    box-sizing: border-box;
    margin: 5px;
}

.scs-componentgroup-style-14 {
    box-shadow: 2px 2px 4px 0 rgb(51 51 51 / 60%);
    box-sizing: border-box;
    margin: 5px;
}

.scs-componentgroup-homepage-block,
.scs-componentgroup-homepage-block-light {
    box-shadow: 2px 2px 4px 0 rgb(51 51 51 / 60%);
    box-sizing: border-box;
    //margin: 5px;
    transition: border-bottom-color 0.2s ease-in-out;
    // border-bottom: 8px solid var(--color-gray-medium);
    line-height: 110%;

    &:not(:has(a.scs-button-button)):hover {
        border-bottom-color: var(--color-secondary-button);
    }

    pointer-events: none;

    button,
    a,
    a:hover,
    a:visited,
    a:focus {
        text-decoration: none;
        pointer-events: auto;
    }

    &>*>* {
        background-image: linear-gradient(0deg, rgba(0 0 0 /.8), transparent);
    }
}

.scs-componentgroup-homepage-block-light>*>* {
    background-image: linear-gradient(0deg, rgba(255 255 255 /.8), transparent);
}

body.scs-edit-mode .scs-componentgroup-homepage-block,
body.scs-edit-mode .scs-componentgroup-homepage-block-light {
    pointer-events: auto;
}

*[class^="scs-divider"] {
    hr {
        border-style: solid;
        border-top-width: 1px;
    }
}

.scs-divider {

    &-default-style hr,
    &-primary hr {
        border-color: var(--color-grey-dark);
    }

    &-secondary hr {
        border-color: var(--color-primary);
    }

    &-thin-gradient hr {
        border-image: linear-gradient(to right, transparent 10%, var(--color-grey-medium) 50%, transparent 90%) 1;
        border-width: 2px 0 0;
    }
}

.scs-button {
    .scs-button-text {
        cursor: pointer !important;
    }

    &.scs-button-default-style {
        .scs-button-button {
            text-decoration: none;
            font-weight: 700;
            padding: 0.75rem 1.5rem;
            margin: 0;
            text-align: center;
            background-color: var(--color-primary);
            color: white;
            font-size: 14px;
            line-height: 20px;
            border: 1px solid var(--color-primary);

            &:hover {
                .scs-button-text {
                    color: var(--color-primary);
                }

                background: white;
                color: var(--color-secondary);
                border: 1px solid var(--color-primary);
            }

            &:active {
                border: 1px solid var(--color-primary-dark);
                color: #fff;

                .scs-button-text {
                    color: var(--color-primary-dark);
                }
            }

            &:focus {
                outline: 1px dotted #4f4f4f;
            }
        }
    }

    &.scs-button-video {
        .scs-button-button {
            text-decoration: none;
            font-weight: 700;
            padding: 0.75rem 1.5rem;
            margin: 0;
            text-align: center;
            background-color: var(--color-secondary-button);
            color: var(--color-white);
            border: 1px solid var(--color-gray-light);
            font-size: 14px;
            line-height: 20px;

            &:hover {
                background-color: var(--color-secondary-button-hover);
                border: 1px solid var(--color-secondary-button);
                color: var(--color-black);
                text-decoration: none;

                .scs-button-text {
                    color: black;
                }
            }

            &:active {
                background-color: var(--color-gray-medium);
                border: 1px solid var(--color-gray-dark);
                text-decoration: none;
            }

            &:focus {
                outline: 1px dotted #4f4f4f;
            }
        }

        .scs-button-text {
            color: var(--color-white);
            cursor: pointer;

            &::before {
                font-family: "Material Symbols Outlined";
                content: "\e1c4";
                font-size: 1.5rem;
                vertical-align: middle;
                font-weight: 300;
                display: inline-block;
                margin-inline-end: 0.5rem;
            }
        }
    }

    &.scs-button-style-2 {
        .scs-button-button {
            text-decoration: none;
            font-weight: 700;
            padding: 0.75rem 1.5rem;
            margin: 0;
            text-align: center;
            background-color: var(--color-secondary-button);
            color: var(--color-white);
            border: 1px solid var(--color-gray-light);
            font-size: 14px;
            line-height: 20px;

            &:hover {
                background-color: var(--color-secondary-button-hover);
                border: 1px solid var(--color-secondary-button);
                color: var(--color-black);
                text-decoration: none;
            }

            &:active {
                background-color: var(--color-gray-medium);
                border: 1px solid var(--color-gray-dark);
                text-decoration: none;
            }

            &:focus {
                outline: 1px dotted #4f4f4f;
            }
        }

        &.scs-button-text {
            color: var(--color-white);
            cursor: pointer;

            &:hover {
                color: var(--color-black);
            }
        }
    }

    &.scs-button-style-3 {
        .scs-button-button {
            text-decoration: none;
            font-weight: 700;
            padding: .75rem 1.5rem;
            margin: 0;
            text-align: center;
            background-color: white;
            color: black;
            border: 1px solid var(--color-gray-extra-light);
            font-size: 14px;
            line-height: 20px;

            &:hover {
                background-color: var(--color-gray-extra-light);
                border: 1px solid var(--color-gray-extra-light);
                color: var(--color-black);
                text-decoration: underline;
                cursor: pointer;
            }

            &:active {
                background-color: var(--color-primary);
                border: 1px solid var(--color-gray-extra-light);
                color: white;
                text-decoration: none;
            }

            &:focus {
                outline: 1px dotted #4f4f4f
            }
        }

        &.scs-button-text {
            color: var(--color-black);
            cursor: pointer;

            &:hover {
                color: var(--color-black);
                text-decoration: underline;
                cursor: pointer;
            }

            &:active {
                color: white;
                text-decoration: none;
            }
        }
    }

}

.scs-cobrowse {
    &-style-1 .scs-button-button {
        text-decoration: none;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-align: center;
        font-size: 16px;
        color: #000;
        background: 0 0;
    }

    &-style-1 .scs-button-text {
        color: #000;
    }

    &-style-1 .scs-button-text:hover {
        color: #000;
        cursor: pointer;
        text-decoration: underline;
    }

    &-style-2 .scs-button-button {
        text-decoration: none;
        padding: 2px !important;
        background: 0 0;
    }

    &-style-2 .scs-button-text {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px;
        color: #000;
    }

    &-style-2 .scs-button-text:hover {
        color: #000;
        cursor: pointer;
        text-decoration: underline;
    }

    &-style-2 .scs-button-text::before {
        cursor: pointer;
        padding-right: 4px;
        vertical-align: -30%;
    }
}

.scs-title-text {
    display: block;
    color: var(--color-grey-dark);
    font-size: 2.625rem;
    font-weight: 700;
    line-height: 1;
    width: 100%;
    padding-bottom: 0.5rem;
    font-family: var(--font-heading);
    // padding-bottom: 1.5rem;
    // margin-block: 2rem;
    border-bottom: 1px solid var(--color-grey-medium);
}

.scs-title-no-underline {
    &.scs-title {
        .scs-title-text {
            display: block;
            color: var(--color-grey-dark);
            font-size: 2.625rem;
            font-weight: 700;
            line-height: 1;
            width: 100%;
            padding-bottom: 0.5rem;
            // padding-bottom: 1.5rem;
            // margin-block: 2rem;
            border-bottom: unset;
        }
    }
}

.scs-title-pull-quote {
    display: block;
    padding-block: 0.5rem;
    border-left-style: solid;
    border-left-color: var(--color-primary);
    border-left-width: 0.3125rem;
    background-color: var(--color-secondary);
    margin-block: 2rem !important;
    margin-inline: auto !important;
    min-width: 75%;
    max-width: 95vw;
    width: min-content;

    &.scs-title {
        .scs-title-text {
            overflow: unset;
            border: none;
            margin-block: 0;
            padding: 20px 50px;
            color: var(--color-black);
            font-family: var(--font-heading);
            font-weight: 700;
            font-size: 1.75rem;
            line-height: 1.2;
            text-align: center;

            div {
                text-align: left;
                font-weight: 300;
            }
        }
    }
}

body {
    &.scs-edit-mode {
        .scs-title-visually-hidden {
            display: block;
            color: var(--color-grey-dark);
            font-size: 2.625rem;
            font-weight: 700;
            line-height: 1;
            padding-bottom: 0.5rem;
            font-family: var(--font-heading);
            // padding-bottom: 1.5rem;
            // margin-block: 2rem;
            border-bottom: 1px solid var(--color-grey-medium);
            outline: 4px dashed gainsboro;

        }
    }
    &:not(.scs-edit-mode) {
        .scs-title-visually-hidden {
            clip: rect(1px, 1px, 1px, 1px);
            clip-path: inset(50%);
            height: 1px;
            width: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
        }
    }
}

*[class^="scs-paragraph"] {
    font-size: 1rem;
}

.scs-paragraph {
    &-default-style {
        color: var(--color-black);
    }

    &-dark-bg {
        color: var(--color-white);
    }

    &-branded-li {
        color: var(--color-black);

        li {
            padding: 0.25rem 0.25rem 0.25rem 0.5rem;

            &::marker {
                content: "▶";
                color: var(--color-primary);
                font-size: 0.7rem;
            }
        }
    }

    &-disclaimer {
        font-size: 0.75rem;
        font-style: italic;
    }

    &-map-title {
        font-size: 1.25rem;
        font-weight: 700;
    }

    &-statistic {
        color: var(--color-black);
        line-height: 3rem;
        font-size: 3rem;

        &-oneline {
            line-height: 1.2;
        }
    }
}

@media screen and (max-width:767px) {
    .scs-paragraph:not(.scs-paragraph-edit) table {
        border: 0;
    }

    .scs-paragraph:not(.scs-paragraph-edit) table caption {
        font-size: 1.3em;
    }

    .scs-paragraph:not(.scs-paragraph-edit) table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .scs-paragraph:not(.scs-paragraph-edit) table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
    }

    .scs-paragraph:not(.scs-paragraph-edit) table td {
        border-bottom: 1px solid #ddd;
        display: block;
        text-align: right;
    }

    .scs-paragraph:not(.scs-paragraph-edit) table td::before {
        content: attr(data-label);
        float: left;
        font-weight: 700;
    }

    .scs-paragraph:not(.scs-paragraph-edit) table td:last-child {
        border-bottom: 0;
    }
}

.scs-document-cap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
}

.scs-document-title {
    position: relative;
    z-index: 2;
    padding: 0.5em;
    opacity: 0.54;
    background-color: #000;
}

.scs-document-desc {
    font-style: italic;
    font-size: 12px;
    color: #ccc;
    padding-top: 2px;
}

.scs-document-capb {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0%;
    z-index: 1;
    opacity: 0.5;
    background-color: #000;
}

.scs-socialbar-default-style {
    background-color: transparent;
}

.scs-contentlist.scs-contentlist-style-1 ol.scs-pagination li {
    font-size: 14px;
    margin: 0 4px;
    color: #333;
    line-height: 26px;
    height: 26px;
    min-width: 28px;
}

.scs-contentlist.scs-contentlist-style-1 ol.scs-pagination li.scs-clickable {
    background-color: #e4e8ea;
    border-radius: 4px;
}

.scs-contentlist.scs-contentlist-style-1 ol.scs-pagination li.scs-clickable a {
    padding: 0 10px;
}

.scs-contentlist.scs-contentlist-style-1 ol.scs-pagination li.scs-clickable:hover {
    color: #fff;
    background-color: #85bbe7;
}

.scs-contentlist.scs-contentlist-style-1 ol.scs-pagination li.scs-clickable.scs-current {
    color: #fff;
    background-color: #027bc7;
}

.scs-contentlist.scs-contentlist-style-1 ol.scs-pagination li.scs-clickable.scs-disabled {
    background-color: #ececec;
    color: #9e9e9e;
}

.news-article-default-style .scs-image {
    border: 1px solid #0572ce;
    box-sizing: border-box;
    padding: 5px;
    font-size: 16px;
    color: #333;
}

.news-article-style-1 .scs-image {
    border: 1px solid red;
    box-sizing: border-box;
    padding: 5px;
    font-size: 18px;
    color: #333;
}

.scs-title-style-base,
.scs-title-style-heading-l,
.scs-title-style-heading-m,
.scs-title-style-heading-s,
.scs-title-style-heading-xl,
.scs-title-style-sub-title,
.scs-title-style-title {
    padding: 0;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0.5em;
    margin-left: 0;
}

.scs-title-style-title {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.4em;
    text-align: center;
    text-transform: uppercase;
}

.scs-title-style-sub-title {
    font-style: italic;
    font-weight: 400;
    font-size: 0.8333em;
    line-height: 1.4em;
    text-align: center;
}

.scs-title-style-heading-xl {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.4em;
}

.scs-title-style-heading-l {
    font-style: italic;
    font-weight: 400;
    font-size: 0.8333em;
    line-height: 1.4em;
}

.scs-title-style-heading-m {
    font-weight: 700;
    font-size: 0.6666em;
    line-height: 1.4em;
}

.scs-title-style-heading-s {
    font-style: italic;
    font-weight: 400;
    font-size: 0.5em;
    line-height: 1.4em;
}

.scs-paragraph-style-base,
.scs-paragraph-style-body-l,
.scs-paragraph-style-body-m,
.scs-paragraph-style-body-s,
.scs-paragraph-style-cite,
.scs-paragraph-style-code,
.scs-paragraph-style-heading-l,
.scs-paragraph-style-heading-m,
.scs-paragraph-style-heading-s,
.scs-paragraph-style-heading-xl,
.scs-paragraph-style-heading-xs,
.scs-paragraph-style-quote {
    padding: 0;
    margin-top: 0.3em;
    margin-right: 0;
    margin-bottom: 0.3em;
    margin-left: 0;
}

.scs-paragraph-style-heading-xl {
    font-weight: 700;
    font-size: 2em;
    line-height: 1.4em;
}

.scs-paragraph-style-heading-l {
    font-style: italic;
    font-weight: 400;
    font-size: 1.5em;
    line-height: 1.4em;
}

.scs-paragraph-style-heading-m {
    font-weight: 700;
    font-size: 1.125em;
    line-height: 1.4em;
}

.scs-paragraph-style-heading-s {
    font-style: italic;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.4em;
}

.scs-paragraph-style-heading-xs {
    font-weight: 700;
    font-size: 0.8125em;
    line-height: 1.4em;
}

.scs-paragraph-style-body-l {
    font-size: 1.125em;
    line-height: 1.4em;
}

.scs-paragraph-style-body-m {
    font-size: 1em;
    line-height: 1.4em;
}

.scs-paragraph-style-body-s {
    font-size: 0.8125em;
    line-height: 1.4em;
}

.scs-paragraph-style-code {
    padding-left: 1.5em;
    font-family: monospace, serif;
    font-size: 0.8125em;
    line-height: 1.3em;
    border: 1px solid silver;
}

.scs-paragraph-style-quote {
    padding-left: 1.5em;
    font-style: italic;
    font-size: 0.8125em;
    line-height: 1.3em;
    border-left: 1px solid silver;
}

.scs-paragraph-style-cite {
    font-size: 0.8125em;
}

.scs-facebook-icon {
    background-image: url(facebook.png);
}

.scs-twitter-icon {
    background-image: url(twitter.png);
}

.scs-linkedin-icon {
    background-image: url(linkedin.png);
}

.scs-googleplus-icon {
    background-image: url(googleplus.png);
}

.scs-youtube-icon {
    background-image: url(youtube.png);
}

.scs-flickr-icon {
    background-image: url(flickr.png);
}

.scs-contentsearch-style-1 .scs-search-input {
    padding: 0 5px 0 5px;
    width: calc((100% - 12px));
    height: 27px;
    font-size: 12px;
    color: #333;
    border: 1px solid #c4ced7;
}

.scs-contentsearch-style-1 .scs-search-input[data-show-icon="true"] {
    width: calc((100% - 37px));
    padding-right: 30px;
}

.scs-contentsearch-style-1 .scs-search-button {
    top: 1px;
    right: 0;
    width: 29px;
    height: 25px;
    margin: 1px 1px 1px 0;
    border-left: 1px solid #dfe3e7;
    background-size: 15px auto;
    background-position: 6px;
    background-color: #f5f5f5;
}

.scs-contentsearch-style-1 .scs-search-button:hover {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxNXB4IiBoZWlnaHQ9IjE1cHgiIHZpZXdCb3g9IjAgMCAxNyAxNyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTcgMTciIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiM4NUJCRTciIGQ9Ik0xNS42MjUsMTMuNzE3bC0zLjQzOC0zLjI1QzEyLjgxMiw5LjQ2NSwxMy4yNSw4LjM0LDEzLjI1LDcuMDljMC0zLjM3NS0yLjc1LTYuMTI2LTYuMTI1LTYuMTI2UzAuOTk5LDMuNzE1LDAuOTk5LDcuMDljMCwzLjM3NiwyLjc1LDYuMTI2LDYuMTI1LDYuMTI2YzEuMjUsMCwyLjQzNy0wLjM3NSwzLjQzOC0xLjA2NGwzLjE4OCwzLjUwMmMwLjQ5OSwwLjQ5OCwxLjMxMiwwLjQ5OCwxLjg3NSwwLjA2MUMxNi4xMjUsMTUuMDI3LDE2LjEyNSwxNC4yMTUsMTUuNjI1LDEzLjcxNyBNNy4xMjUsMTEuMjc3Yy0yLjMxMiwwLTQuMjUtMS44NzUtNC4yNS00LjI1YzAtMi4zNzUsMS44NzUtNC4yNSw0LjI1LTQuMjVzNC4yNSwxLjg3NSw0LjI1LDQuMjVjMCwwLjc1LTAuMTg4LDEuNDM4LTAuNTYzLDIuMDYyQzEwLjA2MiwxMC40MDIsOC42ODcsMTEuMjc3LDcuMTI1LDExLjI3NyIvPjwvc3ZnPg==);
}

.scs-contentsearch-style-2 .scs-search-input {
    width: 100%;
    height: 34px;
    font-size: 14px;
    color: #555;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    border-color: #ccc;
    box-sizing: border-box;
    padding: 6px 12px;
}

.scs-contentsearch-style-2 .scs-search-input[data-show-icon="true"] {
    width: 100%;
    padding-right: 40px;
}

.scs-contentsearch-style-2 .scs-search-button {
    top: 1px;
    right: 0;
    width: 38px;
    height: 30px;
    margin: 1px 1px 1px 0;
    box-sizing: border-box;
    border-left: 1px solid #ccc;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-size: 14px auto;
    background-position: 10px;
}

.scs-contentsearch-style-2 .scs-search-button:hover {
    background-color: #e6e6e6;
}

.rss-caption-heading-default-style .rss-caption-heading {
    background: #be2c12;
    color: #fff;
    font-family: Verdana;
    font-size: 15px;
}

.rss-caption-heading-custom-style-1 .rss-caption-heading {
    background: #0f9633;
    color: #fff;
    font-family: Verdana;
    font-size: 15px;
}

.rss-caption-heading-custom-style-2 .rss-caption-heading {
    background: #746763;
    color: #fff;
    font-family: Verdana;
    font-size: 15px;
}

.scs-cookie-consent {
    border: 1px solid #d9d9d9;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    background-color: #f0f0f0;
    align-items: center;
    font-family: Helvetica Neue, Helvetica, Arial, Utkal, sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #262626;
    line-height: 1.4;
    box-sizing: border-box;
    padding: 20px 70px 20px 15px;
    margin-left: 10px;
    display: inline-block;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99999;
}

.scs-cookie-consent a {
    color: #006598;
    font-weight: 700;
    text-decoration: none;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

.scs-cookie-consent .scs-cookie-consent-close {
    display: block;
    float: right;
    margin: 0;
    background-color: #000;
    border-radius: 100px;
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
    width: 36px;
    height: 36px;
    color: #fff;
    text-align: center;
    background-image: url(x-close.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px 12px;
}

.scs-cookie-consent a:hover {
    color: red;
}

.pswp .scs-lightbox-caption {
    text-align: center;
    max-width: none;
    font-size: 14px;
}

.pswp .scs-lightbox-caption small {
    font-size: 12px;
}

.sl-slider {
    .scs-component-container.scs-componentgroup {
        &>.scs-container-styles {
            &>.scs-component-content {
                display: flex;
                align-items: center;
                max-height: 150px;

                &>.scs-row {
                    &>.scs-col {
                        &>.scs-component-container {
                            &>.scs-component-bounding-box {
                                display: flex;
                                justify-content: space-evenly;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sl-two-columns, .sl-three-columns {
    .scs-component-container.scs-componentgroup, .scs-component-container.scs-sectionlayout{
        padding: unset;
    }
}